





















































import Vue from 'vue'
import { GetterTypes, ActionTypes } from '@/store'
import User from '@/models/User'
import imageLogo from '@/assets/images/logo.svg'
import api, { routers } from '@/api'

export default Vue.extend({
    name: 'Login',
    data: () => ({
        form: {
            email: '' as string,
            password: '' as string,
            remember: true,
        },
        loading: false,
        errorMessage: '',
    }),
    computed: {
        currentUser(): User {
            return this.$store.getters[GetterTypes.USER]
        },
        currentToken(): string {
            return this.$store.getters[GetterTypes.TOKEN]
        },
        getLogo(): string {
            return imageLogo
        },
    },
    methods: {
        async sendForm() {
            try {
                this.loading = true
                this.errorMessage = ''

                const {
                    data: { data },
                } = await api.post(routers.Users.AUTH, this.form)

                //seta os dados
                this.$store.dispatch(ActionTypes.SET_USER, data.user)
                this.$store.dispatch(ActionTypes.SET_TOKEN, data.token)
                this.$store.dispatch(ActionTypes.SET_CSRF_TOKEN, data.csrf_token)

                //move para inicio
                // location.href = '/'
                this.$router.push({ path: '/' })
            } catch (err) {
                this.loading = false
                this.errorMessage =
                    err.response.data.message || 'Falha na requisição'
            }
        },
    },
    mounted() {
        console.log('criado login')
        setTimeout(() => {
            // this.$store.dispatch(MutationTypes.SET_TOKEN,'123')
            // this.$store.commit(MutationTypes.SET_USER, {
            //     id: 123,
            //     name: 'Walison Gomes',
            //     email: 'wgwalisongomes@gmail.com'
            // } as User)
        }, 1000)
    },
})
